import React from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import Posts from '../components/posts';
import Layout from "../components/layout";
import resolveUrl from "../utils/urls/resolve-url";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const BankPromotions = ({ 
  data: { promo, promoEnd, mysqlTerms, allMysqlTerms }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  return (
    <Layout pathname={pathname}>
    <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(" ")}>
      <Breadcrumbs theme='white' items={breadcrumbs} />
    </div>
    <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
      <div className={["blueToGray", b.mb4].join(" ")}>
        <h1 className={["blackToWhite",'big-title', b.mb2,b.mt4].join(" ")}>Promocje bankowe</h1>
        <BlogContent content={mysqlTerms.description} className={[b.mb3,'font-size-20',b.fontWeightNormal].join(' ')}/>
      </div>
      <div className={[b.row,b.mb3,b.mbLg5].join(' ')}>
        {allMysqlTerms.nodes.map(({ term_normalized, path, term },i) => (
          <div className={[b.colLg3,b.pxLg1].join(' ')} key={i}>
            <Link to={resolveUrl(path, term_normalized)}>
              <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                <div className='category-bl'/>
                <div className={b.p3}>{term}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      
      <h2 className={["blueToWhite",b.mb3,b.mbLg4].join(' ')}>Wszystkie promocje</h2>
      <Posts items={[...promo.nodes, ...promoEnd.nodes]} bankpromo />
    </div>
    </Layout>
  )
}

export default BankPromotions

export const query = graphql`
query BankPromotions($mysqlId: Int!) {
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description
    title
    term_normalized
    path
  }
  allMysqlTerms(filter: {cluster: {eq: "product-promo"}}) {
    nodes {
      term_normalized
      term
      path
    }
  }
  promo: allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }}) {
    nodes {
      title
      image_description
      description
      url
      term_normalized
      terms_path
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 70)
        }
      }
    }
  }
  promoEnd: allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { eq: 1 }}) {
    nodes {
      title
      image_description
      description
      url
      term_normalized
      terms_path
      is_promo_end
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 70)
        }
      }
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`